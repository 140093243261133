<span class="drilldown-navigator">
  @for (drilldownItem of drilldownList; track drilldownItem.drilldownKey; let isFirst = $first) {
    @if (!isFirst) {
      <cds-icon
        shape="angle"
        direction="right"
        size="16"
      />
    }
    <a
      (click)="navigateTo(drilldownItem)"
      (keyup.enter)="navigateTo(drilldownItem)"
      class="clickable text-ellipsis"
    >
      {{ drilldownItem.drilldownKey }}
    </a>
  }
</span>
