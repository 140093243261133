<div
  [class.clickable]="!!viewLink"
  (click)="goToTargetLink()"
  (keyup.enter)="goToTargetLink()"
  class="dpa-card"
>
  <div class="dpa-card-title">
    <dpa-ellipsis-with-tooltip>
      {{ title }}
    </dpa-ellipsis-with-tooltip>
    @if (tooltip) {
      <dpa-tooltip
        [tooltipTemplate]="tagTooltip"
        [focusEnabled]="true"
      >
        <cds-icon
          shape="info-circle"
          size="24"
          class="status-icon ml-x1"
        />
      </dpa-tooltip>
    }
    <ng-template #tagTooltip>
      {{ tooltip }}
    </ng-template>
  </div>
  @if (subtitle) {
    <h6 class="dpa-card-sub-title">
      <dpa-ellipsis-with-tooltip>
        {{ subtitle }}
      </dpa-ellipsis-with-tooltip>
    </h6>
  }
  <div class="dpa-card-body">
    @if (status === '' || status === undefined) {
      <dpa-timeout-loader [skeletonClassName]="SKELETON_CLASS_NAME.RESULTS_DATA" />
    } @else {
      <div class="status">
        <h1 class="ml-x3 mr-x2 status-colors {{ status | lowercase }}">
          {{ status }}
        </h1>
      </div>
    }
  </div>
  <ng-container>
    <div class="dpa-card-footer">
      <div class="clr-row clr-flex-items-sm-middle">
        <div class="clr-col-sm">
          <button
            [attr.aria-label]="'DASHBOARD_ACTIONS.VIEW_DAHSBOARD_DETAILS_ARIA_LABEL' | translate: { name: title }"
            [disabled]="!viewLink"
            class="btn btn-sm btn-link"
          >
            {{ 'COMMON_ACTIONS.VIEW' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
