<div
  [class.compact]="isCompact"
  [class.btn-icon]="isCompact"
  [attr.role]="isCompact ? 'radiogroup' : 'dropdown'"
  class="btn-group"
>
  @if (!isCompact) {
    <div class="chart-selection-container">
      <div
        (click)="toggleList()"
        class="toggle-container"
      >
        <cds-icon
          [attr.shape]="selectedChartType | dpaIconName: '' : '-chart'"
          [attr.size]="16"
          class="chart-type"
        />
        <input
          [value]="'WIDGET_CUSTOMIZE.WIDGET_CHART_' + selectedChartType | translate"
          class="clr-input chart-selection-input"
          readonly
        />
      </div>
      @if (showChartTypesList) {
        <ul class="dropdown-menu chart-selection-list">
          @for (chartType of chartTypes; track chartType) {
            <li
              [class.active]="selectedChartType === chartType"
              [class.disabled]="allowedChartTypes && !allowedChartTypes.includes(chartType)"
              [attr.chart-type]="chartType | lowercase"
              (click)="selectChartType(chartType)"
              class="dropdown-item"
            >
              <cds-icon
                [attr.shape]="chartType | dpaIconName: '' : '-chart'"
                [attr.size]="16"
                class="chart-type"
              />
              <div class="ml-x1">
                {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + chartType | translate }}
              </div>
            </li>
          }
        </ul>
      }
    </div>
  }

  @if (isCompact) {
    @for (chartType of chartTypes; track chartType) {
      <ng-container
        [ngTemplateOutlet]="compactChartIconTemplate"
        [ngTemplateOutletContext]="{
          chartType: chartType,
          chartLabel: 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + chartType | translate,
          isSelected: chartType === selectedChartType,
          clickHandler: selectChartType
        }"
      />
    }
  }
</div>

<ng-template
  #compactChartIconTemplate
  let-chartType="chartType"
  let-isSelected="isSelected"
  let-chartLabel="chartLabel"
  let-clickHandler="clickHandler"
>
  <dpa-tooltip
    [attr.aria-label]="chartLabel"
    [attr.aria-checked]="isSelected"
    [tooltipText]="chartLabel"
    [focusEnabled]="true"
    class="tooltip"
    role="radio"
  >
    <button
      [class.btn-primary]="isSelected"
      [class.btn-outline]="!isSelected"
      [disabled]="allowedChartTypes && !allowedChartTypes.includes(chartType)"
      (click)="clickHandler(chartType, $event)"
      class="btn btn-small chart-button"
    >
      <div class="button-label">
        <cds-icon
          [class.selected-chart]="isSelected"
          [attr.shape]="chartType | dpaIconName: '' : '-chart'"
          [attr.size]="24"
          class="chart-type"
        />
      </div>
    </button>
  </dpa-tooltip>
</ng-template>
