@if (ngxChart) {
  <div
    (resized)="onResize()"
    class="chart-container"
  >
    @if (!overlayChart) {
      @if (ngxChart.isMultiDataSet) {
        <dpa-grouped-vertical-chart
          [ngxChart]="ngxChart"
          [scheme]="ngxChart.defaultColorScheme"
          [results]="data"
          [legend]="false"
          [xAxisLabel]="ngxChart.labels.firstGroupBy"
          [yAxisLabel]="yAxisLabel"
          [gradient]="false"
          [showXAxis]="showXAxis"
          [showYAxis]="showYAxis"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="!!yAxisLabel"
          [animations]="true"
          [rotateXAxisTicks]="rotateXAxisTicks"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [trimXAxisTicks]="true"
          [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
          [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
          [customColors]="ngxChart.customColors"
          [showDataLabel]="showDataLabel"
          [dataLabelFormatting]="ngxChart.labels.counterFormatter"
          [yScaleMax]="yScaleMax"
          [isStacked]="chartType !== AggregationWidgetChartType.VERTICAL_GROUP"
          [stackedTooltip]="stackedTooltip"
          [showDetailsLink]="showDetailsLink"
          (viewDetails)="onDrilldown($event)"
        />
      } @else {
        <ngx-charts-bar-vertical
          #chart
          [scheme]="ngxChart.defaultColorScheme"
          [results]="data"
          [legend]="false"
          [xAxisLabel]="ngxChart.labels.firstGroupBy"
          [yAxisLabel]="yAxisLabel"
          [gradient]="false"
          [xAxis]="showXAxis"
          [yAxis]="true"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="!!yAxisLabel"
          [animations]="true"
          [roundEdges]="false"
          [rotateXAxisTicks]="rotateXAxisTicks"
          [trimXAxisTicks]="true"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
          [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
          [customColors]="ngxChart.customColors"
          [showDataLabel]="showDataLabel"
          [dataLabelFormatting]="ngxChart.labels.counterFormatter"
          [yScaleMax]="yScaleMax"
          (select)="onSelect($event)"
        >
          <ng-template
            #tooltipTemplate
            let-model="model"
          >
            @if (model) {
              <ng-container
                [ngTemplateOutlet]="stackedTooltip"
                [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
              />
            }
          </ng-template>
        </ngx-charts-bar-vertical>
      }
    } @else {
      <dpa-bar-vertical-overlay-chart
        #chart
        [isStacked]="ngxChart.isMultiDataSet"
        [scheme]="ngxChart.defaultColorScheme"
        [schemeForOverlay]="overlayChart.defaultColorScheme"
        [results]="data"
        [overlayResults]="overlayData"
        [customColorsForOverlay]="overlayChart.customColors"
        [xAxisLabel]="ngxChart.labels.firstGroupBy"
        [yAxisLabel]="yAxisLabel"
        [yAxisRightLabel]="yAxisRightLabel ?? overlayChart.labels.counter"
        [gradient]="false"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="!!yAxisLabel"
        [animations]="true"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [trimXAxisTicks]="true"
        [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
        [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
        [customColors]="ngxChart.customColors"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="ngxChart.labels.counterFormatter"
        [yScaleMax]="yScaleMax"
        [focussedSequence]="focussedSequence"
        [tooltipTemplate]="overlayTooltipTemplate"
        (select)="onOverlayDrilldown($event)"
      />
    }
  </div>
}
