<h6 class="mt-0 mb-x1">
  {{ 'STANDARD_DASHBOARD.DEVICES_DETAILS.' + key.toUpperCase() | translate }}
</h6>
<div>
  <span class="threshold-pill status-bg-colors good"></span>
  <span class="p4 ml-x2">
    {{ threshold?.isReversed ? '&gt;' : '&lt;' }}
    {{ formattedValueGN }}
  </span>
  <br />
  <span class="threshold-pill status-bg-colors neutral"></span>
  <span class="p4 ml-x2">
    {{ formattedValueGN }}
    ~
    {{ formattedValueNB }}
  </span>
  <br />
  <span class="threshold-pill status-bg-colors poor"></span>
  <span class="p4 ml-x2">
    {{ threshold?.isReversed ? '&lt;' : '&gt;' }}
    {{ formattedValueNB }}
  </span>
</div>
