<div
  (resized)="onResize()"
  class="chart-container"
>
  @if (ngxChart.isMultiDataSet) {
    <dpa-grouped-horizontal-chart
      [ngxChart]="ngxChart"
      [scheme]="ngxChart.defaultColorScheme"
      [results]="data"
      [legend]="false"
      [gradient]="false"
      [showXAxis]="showXAxis"
      [showYAxis]="showYAxis"
      [xAxisLabel]="xAxisLabel"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="!!yAxisLabel"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [maxXAxisTickLength]="maxXAxisTickLength"
      [trimXAxisTicks]="true"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="showDataLabel"
      [yScaleMax]="yScaleMax"
      [isStacked]="chartType !== AggregationWidgetChartType.HORIZONTAL_GROUP"
      [stackedTooltip]="stackedTooltip"
      (viewDetails)="onDrilldown($event)"
    />
  } @else {
    <ngx-charts-bar-horizontal
      #chart
      [scheme]="ngxChart.defaultColorScheme"
      [results]="data"
      [legend]="false"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="ngxChart.labels.firstGroupBy"
      [xAxis]="true"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxis"
      [showYAxisLabel]="!isPercentage && showYAxisLabel"
      [animations]="true"
      [roundEdges]="false"
      [xAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      (select)="onSelect($event)"
    />
  }
</div>
