<ng-template #tooltipTrigger>
  <ng-content />
</ng-template>
<ng-template #scoreTemplate>
  <p class="large-tooltip mt-0">
    {{ scoreName || scoreType }}
    <br />
    <br />
    {{ scoreDefinition }}
  </p>
</ng-template>
@if (scoreDefinition) {
  <dpa-tooltip [tooltipTemplate]="scoreTemplate">
    <ng-container *ngTemplateOutlet="tooltipTrigger" />
  </dpa-tooltip>
} @else {
  <ng-container *ngTemplateOutlet="tooltipTrigger" />
}
