@if (ngxChart) {
  <div
    (resized)="onResize()"
    class="chart-container"
  >
    @if (isStacked) {
      <ngx-charts-bar-horizontal-stacked
        #chart
        [scheme]="ngxChart.defaultColorScheme"
        [results]="data"
        [legend]="false"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="ngxChart.labels.firstGroupBy"
        [xAxis]="true"
        [yAxis]="showYAxis"
        [showXAxisLabel]="showXAxis"
        [showYAxisLabel]="showYAxisLabel"
        [animations]="true"
        [xAxisTickFormatting]="ngxChart.labels.counterFormatter"
        [yAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
        [customColors]="ngxChart.customColors"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="ngxChart.labels.counterFormatter"
        (select)="onSelect($event)"
      >
        <ng-template
          #tooltipTemplate1
          let-model="model"
        >
          @if (model) {
            <ng-container
              [ngTemplateOutlet]="stackedTooltip"
              [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
            />
          }
        </ng-template>
      </ngx-charts-bar-horizontal-stacked>
    } @else {
      <ngx-charts-bar-horizontal-2d
        #chart
        [scheme]="ngxChart.defaultColorScheme"
        [results]="data"
        [legend]="false"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="ngxChart.labels.firstGroupBy"
        [gradient]="false"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="!!yAxisLabel"
        [animations]="true"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [trimXAxisTicks]="true"
        [xAxisTickFormatting]="ngxChart.labels.counterFormatter"
        [yAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
        [customColors]="ngxChart.customColors"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="ngxChart.labels.counterFormatter"
        [groupPadding]="8"
        [barPadding]="8"
        (select)="onSelect($event)"
      >
        <ng-template
          #tooltipTemplate2
          let-model="model"
        >
          @if (model) {
            <ng-container
              [ngTemplateOutlet]="stackedTooltip"
              [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
            />
          }
        </ng-template>
      </ngx-charts-bar-horizontal-2d>
    }
  </div>
}
