<div
  [class.active]="active"
  class="legend-item"
>
  <div
    [class.selected]="selected"
    [style.background-color]="color"
    class="legend-item-color mr-x1"
  ></div>
  <dpa-ellipsis-with-tooltip class="legend-item-label">
    {{ name }}
  </dpa-ellipsis-with-tooltip>
</div>
