<ng-template
  #chartMarker
  let-markerInfo
>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g class="dpa-marker">
      <g [attr.transform]="markerInfo.transformText">
        @if (!markerInfo.hideMarker) {
          <path
            [attr.d]="markerInfo.path"
            [attr.fill]="markerInfo.color"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipDisabled]="!tooltipTemplate"
            [tooltipContext]="markerInfo.tooltipContext"
            (click)="handleClick(markerInfo)"
            ngx-tooltip
          />
        }
        <text
          [attr.x]="40"
          [attr.y]="5"
          text-anchor="middle"
        >
          {{ markerInfo.label }}
        </text>
      </g>
      <g class="reference-lines">
        @if (markerInfo.showXReferenceLine) {
          <line
            [attr.x1]="markerInfo.xPos"
            [attr.y1]="markerInfo.yPos"
            [attr.x2]="0"
            [attr.y2]="markerInfo.yPos"
          />
        }
        @if (markerInfo.showYReferenceLine) {
          <line
            [attr.x1]="markerInfo.xPos"
            [attr.y1]="markerInfo.yPos"
            [attr.x2]="markerInfo.xPos"
            [attr.y2]="markerInfo.chartHeight"
          />
        }
      </g>
    </g>
  </svg>
</ng-template>
