<dpa-tooltip [tooltipTemplate]="tooltipTemplate">
  <dpa-threshold-range-pill
    [original]="tableColumnMap[experienceScoreType][dataValue]?.original"
    [isNA]="tableColumnMap[experienceScoreType][dataValue]?.formatted === 'COMMON_MESSAGES.UNKNOWN_VALUE'"
    [threshold]="threshold"
  />
  {{ tableColumnMap[experienceScoreType][dataValue]?.formatted | translate }}
</dpa-tooltip>
<ng-template #tooltipTemplate>
  <div class="tooltip-container">
    <dpa-threshold-range-indicator
      [key]="dataValue"
      [threshold]="threshold"
    />
  </div>
</ng-template>
